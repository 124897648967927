import api from "@/base/utils/request";

// 获取我的文章列表
export const myArticleList = data => {
    return api({
        url: "/admin/portal/article/myArticles",
        method: "post",
        data
    });
};
// 获取我的文章tab
export const myArticleTab = data => {
    return api({
        url: "/admin/portal/article/tabs",
        method: "post",
        data
    });
};

// 获取删除文章
export const dleArticle = data => {
    return api({
        url: "/admin/portal/article/softDelete",
        method: "post",
        data
    });
};

/**
 * 获取发布配置
 */
export const getPostConfig = () => {
    return api({
        url: "/admin/portal/article/getPostConfig",
        method: "post"
    });
};
