<template>
  <div class="list-page">
    <MyScreen :filterForm="myselfForm" :uploadFilter="callbackForm"></MyScreen>
    <list-tabs
      v-model="myselfForm.tab"
      :isLoading="show_loading"
      :tabs="tabArr"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="my-article-list" v-loading="show_loading">
      <div
        class="my-article-item"
        v-for="(article, index) in my_article_list"
        :key="article.id"
      >
        <div class="item-left" v-if="article.images.length">
          <el-image
            style="width: 100%; height: 100%; cursor: pointer"
            fit="cover"
            lazy
            :src="article.images[0].url"
            @click="$previewImage([article.images[0].url])"
          >
          </el-image>
        </div>
        <div class="item-right">
          <h3
            @click="previewArticle(article)"
            class="article-title"
          >
            {{ article.title }}
            <!-- 是公众号文章就显示标签 -->
            <el-tag size="mini" v-if="article.article_type === 1"
              >公众号文章</el-tag
            >
          </h3>
          <div class="article-come">
            <span style="margin-right: 10px" class="article-source">{{
              article.category_name
            }}</span>
            <span class="article-time">{{ article.create_time }}</span>
          </div>
          <div
            class="article-status"
            :style="
              article.status == 7
                ? 'color:#67757c'
                : article.status == 3 || article.status == 5
                ? 'color:#FF0000'
                : ''
            "
          >
            <span class="status-text">{{ article.status_text }}</span>
            <span v-if="article.status === 3 || article.status === 5"
              >原因：{{ article.failed_message }}</span
            >
          </div>
          <!-- 是公众号文章不显示这些东西 -->
          <div
            class="article-msg"
            v-if="article.status === 1 && article.article_type !== 1"
          >
            <span class="msg-item">阅读{{ article.read_count }}</span>
            <span class="msg-item">评论{{ article.comments_count }}</span>
            <span class="msg-item">收藏{{ article.collects_count }}</span>
            <span class="msg-item">转发{{ article.share_count }}</span>
            <!-- <span class="msg-item">点赞{{article.likes_count}}</span> -->
          </div>
          <div
            class="article-operation"
            v-if="
              article.status === 0 ||
              article.status === 3 ||
              article.status === 5 ||
              article.status === 7
            "
          >
            <span
              class="article-edit"
              style="margin-right: 10px"
              @click="handleToEdit(article.id, article)"
              >编辑</span
            >
            <span class="article-del" @click="handleDleArticle(article.id)"
              >删除</span
            >
          </div>
        </div>
      </div>
      <div class="no-result" v-if="show_no_result">暂无数据</div>
    </div>

    <div style="margin-top: 60px">
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      ></Pagination>
    </div>
    <article-viewer
      v-model="preview.show"
      :options="preview.data"
    ></article-viewer>
  </div>
</template>

<script>
import { getPreviewData } from '../api/base'
import { dleArticle, myArticleList, getPostConfig } from '../api/my-article'

import MyScreen from '../components/MyArticle/MyScreen'
import Pagination from '@/base/components/Default/Pagination'
import ArticleViewer from '@/base/components/Preview/ArticleViewer'
import previewArticle from '../mixins/previewArticle'

export default {
  name: 'my-article',
  mixins: [previewArticle],
  data() {
    return {
      myselfForm: {
        page_size: 15,
        tab: 'normal',
        category_id: 0,
        type: -1,
        start_time: -1,
        end_time: -1,
        keyword: '',
      },
      filters: {
        normal: null,
        draft: null,
        published: null,
        checking: null,
        unchecked: null,
        checked: null,
        rejected: null,
        recalled: null,
      },
      pageData: {},
      tabArr: [
        {
          name: 'normal',
          label: '全部',
        },
        {
          name: 'draft',
          label: '草稿',
        },
        {
          name: 'published',
          label: '已发布',
        },
        {
          name: 'checking',
          label: '待审核',
        },
        {
          name: 'unchecked',
          label: '未通过',
        },
        {
          name: 'checked',
          label: '待发布',
        },
        {
          name: 'rejected',
          label: '不采用',
        },
        {
          name: 'recalled',
          label: '撤回',
        },
      ],
      my_article_list: [{ images: [] }],
      show_loading: false,
      show_no_result: false,
      showPreview: false,
      select_icon: [],
      preview: {
        data: {},
        show: false,
      },
    }
  },
  components: {
    MyScreen,
    Pagination,
    ArticleViewer,
  },
  created() {
    this.show_loading = true
    this.getPostConfig()
    this.getMyArticleList(this.myselfForm)
  },
  methods: {
    getPostConfig() {
      getPostConfig().then((res) => {
        if (res && res.data && res.data.post_check_enable === 0) {
          this.tabArr = this.tabArr.filter(function (it) {
            return it.name !== 'checking' && it.name !== 'unchecked'
          })
        }
      })
    },
    handleToEdit(id, row) {
      if (row.article_type) {
        this.$router.push({
          name: 'EditMyWxArticle',
          query: { id: id, come_from: 'MyArticleList' },
        })
      } else {
        this.$router.push({
          name: 'EditMyArticle',
          query: { id: id, come_from: 'MyArticleList' },
        })
      }
    },
    handleDleArticle(id) {
      this.$confirm('是否删除该文章', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          dleArticle({ id: id })
            .then((res) => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getMyArticleList({
                ...this.myselfForm,
                page: this.pageData.current_page,
              })
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    handleChangeTab(e) {
      if (!this.filters[e.name]) {
        this.myselfForm = {
          page_size: this.pageData.per_page,
          tab: e.name,
          category_id: 0,
          type: -1,
          start_time: -1,
          end_time: -1,
          keyword: '',
        }
      } else {
        this.myselfForm = this.filters[e.name]
      }
      this.getMyArticleList(this.myselfForm)
    },
    callbackForm(e) {
      this.myselfForm = e
      this.getMyArticleList(this.myselfForm)
    },
    changPage(e) {
      this.myselfForm.page_size = e.page_size
      const pageData = { ...this.myselfForm, ...e }
      this.getMyArticleList(pageData)
    },
    getMyArticleList(requestData) {
      this.show_no_result = false
      this.show_loading = true
      this.my_article_list = []
      this.pageData = {}
      myArticleList(requestData)
        .then((res) => {
          this.my_article_list = res.data.data
          this.pageData = res.data
          if (res.data.data.length == 0) {
            this.show_no_result = true
          }
          this.filters[res.data.tab] = {
            ...this.myselfForm,
            page_size: res.data.per_page,
          }
          this.show_loading = false
        })
        .catch(() => {
          this.show_loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.my-article-list {
  width: 100%;
  min-height: calc(100vh - 540px);
  box-sizing: border-box;

  .my-article-item {
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0;
    display: flex;
    align-items: center;

    .item-left {
      width: 220px;
      height: 120px;
      flex-shrink: 0;
      position: relative;
    }

    .item-right {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      font-size: 18px;

      .article-title {
        color: #212121;
        cursor: pointer;

        &:hover {
          color: #1972d6;
        }
      }

      .article-come {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: #67757c;
      }

      .article-status {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #417505;

        .status-text {
          margin-right: 15px;
        }
      }

      .article-msg {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #67757c;

        .msg-item {
          margin-right: 10px;
        }
      }

      .article-operation {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #67757c;

        .article-edit {
          cursor: pointer;
        }

        .article-del {
          cursor: pointer;
        }
      }
    }
  }
}

.no-result {
  height: calc(100vh - 540px);
  width: 100%;
  display: flex;
  color: #909399;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}
</style>
